import { roundPriceTo50CentsString } from '~/utils/Utils'

const usePrices = () => {
  const totalPrice = (gesamtpreis: string|null): string|null => {
    if (gesamtpreis) {
      return roundPriceTo50CentsString(gesamtpreis)
    }
    return null
  }

  const totalPriceOriginal = (
    gesamtpreisOriginal: string|null,
    gesamtpreis: string|null
  ): string|null => {
    const originalPrice = gesamtpreisOriginal ?? 0
    const discountedPrice = gesamtpreis ?? 0
    if (!originalPrice || !discountedPrice) {
      return null
    }
    if (originalPrice <= discountedPrice) {
      return null
    }
    return roundPriceTo50CentsString(originalPrice)
  }

  const totalPriceDiscountPercentage = (
    gesamtpreisOriginal: string|null,
    gesamtpreis: string|null
  ): string|null => {
    const originalPrice = gesamtpreisOriginal ?? 0
    const discountedPrice = gesamtpreis ?? 0
    if (!originalPrice || !discountedPrice) {
      return null
    }
    if (originalPrice <= discountedPrice) {
      return null
    }
    return Math.round(((originalPrice - discountedPrice) / originalPrice) * 100)
  }

  const pricePerNight = (
    min: string|null,
    lastminuteTagespreis: string|null
  ): string|null => {
    if (min) {
      return roundPriceTo50CentsString(min)
    }
    if (lastminuteTagespreis) {
      return roundPriceTo50CentsString(lastminuteTagespreis, true)
    }
    return null
  }

  return {
    totalPrice,
    totalPriceOriginal,
    totalPriceDiscountPercentage,
    pricePerNight
  }
}

export default usePrices
